<template>
  <b-card

    class="blog-edit-wrapper"
  >
    <!-- media -->
    <b-media
      no-body
      vertical-align="center"
    >
      <b-media-aside class="mr-75">
        <b-avatar
          size="38"
          :src="userData.avatar"
        />
      </b-media-aside>
      <b-media-body>
        <h6 class="mb-25">
          {{ userData.fullName || userData.username}}
        </h6>
        <b-card-text>{{ userData.role }}</b-card-text>
      </b-media-body>
    </b-media>
    <!--/ media -->

    <!-- form -->
    <b-form class="mt-2">
      <b-row>
        <b-col md="6">
          <b-form-group
            label="Subject"

            class="mb-2"
          >
            <b-form-input
              id="blog-edit-title"
              v-model="Subject"
            />
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group
            label="Category"

            class="mb-2"
          >
            <v-select
              id="blog-edit-category"
              v-model="Category"

              :options="categoryOption"
            />
          </b-form-group>
        </b-col>
        <b-col md="6">
          
        </b-col>
        <b-col md="6">
          <b-form-group
            label="Status"
            class="mb-2"
          >
            <v-select
              id="blog-edit-category"
              v-model="Hallo"

              :options="statusOption"
            />
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <div>
        <label for="textarea-default">Feedback</label>
        <b-form-textarea
          id="textarea-default"
          placeholder="Feedback"
          v-model="Text"
          rows="3"
        />
      </div>
        </b-col>
        <b-col
          cols="12"
          class="mb-2"
        >
          
        </b-col>
        <b-col
          cols="12"
          class="mt-50"
        >
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mr-1"
            type="reset"
            @click="withTitle"
          >
            Send Feedback

          </b-button>
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            variant="outline-secondary"
          >
            Cancel
          </b-button>
        </b-col>
      </b-row>
    </b-form>
    <!--/ form -->
  </b-card>
</template>

<script>
import {
  BCard, BMedia, BAvatar, BCardText, BMediaAside, BMediaBody, BForm, BRow, BCol, BFormGroup, BFormInput, BImg, BFormFile, BLink, BButton,BFormTextarea 
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import { avatarText } from '@core/utils/filter'
import { db } from '@/firebase/index'
import { doc, setDoc } from "firebase/firestore"; 


export default {
  components: {
    BCard,
    BMedia,
    BAvatar,
    BCardText,
    BMediaAside,
    BMediaBody,
    BForm,
    BLink,
    BImg,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BFormFile,
    vSelect,
    BFormTextarea
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      userData: JSON.parse(localStorage.getItem('userData')),
      avatarText,
      Text: '',
      Category: '',
      Subject: '',
      categoryOption: ['Design', 'Features', 'Questions', 'Improvements'],
      statusOption: ['Anonymous', 'Public'],
      snowOption: {
        theme: 'snow',
      },
    }
  },
  methods: {
    withTitle() {
      this.$swal({
        title: 'Thank you!,',
        text: 'We appreciate your feedback, and work on your topic as soon as possible',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      })
      this.sendFeedback()
    },
    async sendFeedback() {
      await setDoc(doc(db, "FeedBack", this.Category), ({[this.Subject]: {Text: [this.Text], User: this.userData.username},}),{ merge: true });
    } 
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/quill.scss';
@import '@core/scss/vue/pages/page-blog.scss';
</style>
